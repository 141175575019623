import {
  Account,
  LoginRequest,
  RegisterRequest,
  UpdateAccountRequest,
} from './types';
import {
  AuthActionTypes,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  CONFIRMING_REGISTER,
  CONFIRM_REGISTER_SUCCESS,
  CONFIRM_REGISTER_FAILED,
  CHANGING_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  RESETING_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CONFIRMING_RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAILED,
  GET_ACCOUNT_FAILED,
  GET_ACCOUNT,
  ChangeEmailSentAction,
  CHANGE_EMAIL_SENT,
  LogoutAction,
  LOGOUT,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
} from './actionTypes';
import {Dispatch} from 'redux';
import {authService} from '../../services/auth.service';

//#region  Login

export const login = ({email, password}: LoginRequest) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(requestLogin());

    authService.login({email, password}).then(
      (response) => {
        window.localStorage.setItem('token', response!.jwtToken);
        dispatch(loginSuccess(response!.account));
      },
      (error) => {
        dispatch(loginFailed(error));
      }
    );
  };
};

const requestLogin = (): AuthActionTypes => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = (account: Account): AuthActionTypes => ({
  type: LOGIN_SUCCESS,
  account,
});

const loginFailed = (error: string): AuthActionTypes => ({
  type: LOGIN_FAILED,
  error,
});

//#endregion

//#region Logout

export const logout = () => {
  return (dispatch: Dispatch<LogoutAction>) => {
    const dpto = localStorage.getItem('department') 
    const barrio = localStorage.getItem('neighborhood')
    window.localStorage.clear();
    localStorage.setItem('department', dpto);
    localStorage.setItem('neighborhood', barrio);
    dispatch(logouting());
  };
};

const logouting = (): LogoutAction => ({
  type: LOGOUT,
});

//#endregion

//#region  Register

export const register = (account: Account, password: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(registering());

    const registerRequest: RegisterRequest = {
      account,
      password,
    };

    authService.register(registerRequest).then(
      (response) => {
        console.log('success')
        dispatch(registerSuccess());
      },
      (error) => {
        console.log('failure')
        dispatch(registerFailed(error));
      }
    );
  };
};

export const changeEmail = (email: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(changingEmail(email));
  };
};

const changingEmail = (email: string): ChangeEmailSentAction => ({
  type: CHANGE_EMAIL_SENT,
  email: email,
});

const registering = (): AuthActionTypes => ({
  type: REGISTER_REQUEST,
});

const registerSuccess = (): AuthActionTypes => ({
  type: REGISTER_SUCCESS,
});

const registerFailed = (error: string): AuthActionTypes => ({
  type: REGISTER_FAILED,
  error,
});

//#endregion

//#region Verify email

export const verifyEmail = (email: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(verifyingEmail());

    authService.verifyEmail(email).then(
      (response) => {
        console.log(response);
        dispatch(verifyEmailSuccess());
      },
      (error) => {
        console.log(error);
        dispatch(verifyEmailFailed('Error'));
      }
    );
  };
};

const verifyingEmail = (): AuthActionTypes => ({
  type: VERIFY_EMAIL_REQUEST,
});

const verifyEmailSuccess = (): AuthActionTypes => ({
  type: VERIFY_EMAIL_SUCCESS,
});

const verifyEmailFailed = (error: string): AuthActionTypes => ({
  type: VERIFY_EMAIL_FAILED,
  error,
});

//#endregion

//#region Confirm register

export const confirmRegister = (token: string, accountId: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(confirmingRegister());

    authService.confirmRegister(token, accountId).then(
      (response) => {
        dispatch(confirmRegisterSuccess());
      },
      (error) => {
        dispatch(confirmRegisterFailed(error));
      }
    );
  };
};

const confirmingRegister = (): AuthActionTypes => ({
  type: CONFIRMING_REGISTER,
});

const confirmRegisterSuccess = (): AuthActionTypes => ({
  type: CONFIRM_REGISTER_SUCCESS,
});

const confirmRegisterFailed = (error: string): AuthActionTypes => ({
  type: CONFIRM_REGISTER_FAILED,
  error,
});

//#endregion

//#region Modify password

export const modifyPassword = (oldPassword: string, newPassword: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(changingPassword());

    authService.modifyPassword({oldPassword, newPassword}).then(
      (response) => {
        dispatch(changePasswordSuccess());
      },
      (error) => {
        dispatch(changePasswordFailed(error));
      }
    );
  };
};

const changingPassword = (): AuthActionTypes => ({
  type: CHANGING_PASSWORD,
});

const changePasswordSuccess = (): AuthActionTypes => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailed = (error: string): AuthActionTypes => ({
  type: CHANGE_PASSWORD_FAILED,
  error,
});

//#endregion

//#region Reset password

export const resetPassword = (email: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(resetingPassword());

    authService.resetPassword(email).then(
      (response) => {
        dispatch(resetPasswordSuccess());
      },
      (error) => {
        dispatch(resetPasswordFailed(error));
      }
    );
  };
};

const resetingPassword = (): AuthActionTypes => ({
  type: RESETING_PASSWORD,
});

const resetPasswordSuccess = (): AuthActionTypes => ({
  type: RESET_PASSWORD_SUCCESS,
});

const resetPasswordFailed = (error: string): AuthActionTypes => ({
  type: RESET_PASSWORD_FAILED,
  error,
});

//#endregion

//#region Confirm reset password

export const confirmResetPassword = (token: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(confirmingResetPassword());

    authService.confirmResetPassword(token).then(
      (response) => {
        dispatch(confirmResetPasswordSuccess());
      },
      (error) => {
        dispatch(confirmResetPasswordFailed(error));
      }
    );
  };
};

const confirmingResetPassword = (): AuthActionTypes => ({
  type: CONFIRMING_RESET_PASSWORD,
});

const confirmResetPasswordSuccess = (): AuthActionTypes => ({
  type: CONFIRM_RESET_PASSWORD_SUCCESS,
});

const confirmResetPasswordFailed = (error: string): AuthActionTypes => ({
  type: CONFIRM_RESET_PASSWORD_FAILED,
  error,
});

//#endregion

//#region Get account

export const getAccount = () => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(gettingAccount());

    authService.getAccount().then(
      (response) => {
        dispatch(addAccount(response!));
      },
      (error) => {
        dispatch(addAccountFailed(error));
      }
    );
  };
};

export const getAccountById = (accountId: string) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(gettingAccount());

    authService.getAccountById(accountId).then(
      (response) => {
        dispatch(addAccount(response!));
      },
      (error) => {
        dispatch(addAccountFailed(error));
      }
    );
  };
};

const gettingAccount = (): AuthActionTypes => ({
  type: CONFIRMING_RESET_PASSWORD,
});

const addAccount = (account: Account): AuthActionTypes => ({
  type: GET_ACCOUNT,
  account,
});

const addAccountFailed = (error: string): AuthActionTypes => ({
  type: GET_ACCOUNT_FAILED,
  error,
});

//#endregion

//#region  updateProfile

export const updateProfile = (account: Account) => {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch(updatingAccount());

    const updateAccount: UpdateAccountRequest = {
      account,
    };

    authService.updateAccount(account).then(
      (response) => {
        dispatch(updateSuccess());
      },
      (error) => {
        dispatch(upadteFailed(error));
      }
    );
  };
};

const updatingAccount = (): AuthActionTypes => ({
  type: UPDATE_ACCOUNT_REQUEST,
});

const updateSuccess = (): AuthActionTypes => ({
  type: UPDATE_ACCOUNT_SUCCESS,
});

export const upadteFailed = (error: string): AuthActionTypes => ({
  type: UPDATE_ACCOUNT_FAILURE,
  error,
});

//#endregion
